import React, { useState, useEffect } from "react"
import classNames from "classnames"
import debounce from "lodash.debounce"

import { requestForAutoOpenRestFaster } from "../../apiRequests/callToAction"

import DefaultLayout from "../../layout/default"

import HeadingTextareaAndPrompt from "../../components/_V3/SecretPages/HeadingTextareaAndPrompt"
import MainBanner from "../../components/_V3/SecretPages/MainBanner"
import SocialNetworkBanner from "../../components/_V3/SecretPages/SocialNetworkBanner"
import ConsultationForm from "../../components/_V3/SecretPages/ConsultationForm"
import FooterCompact from "../../components/_V2/FooterCompact"
import SendPulseModal from "../../components/_V2/SendPulseModal"

import { mainBanner } from "../../pages-data/_V3/secret/molodo-zeleno/MainBanner/data"
import mainBannerImg from "../../pages-data/_V3/secret/molodo-zeleno/MainBanner/assets/MediaMainBanner.webp"
import { headingTextareaAndPrompt } from "../../pages-data/_V3/secret/molodo-zeleno/HeadingTextareaAndPrompt/data"
import { socialNetworkBanner } from "../../pages-data/_V3/secret/SocialNetworkBanner/data"
import { consultationForm } from "../../components/_V3/SecretPages/ConsultationForm/data"

import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/secret.module.scss"

export default function MolodoZeleno() {
	const metaTags = [
		{
			name: "description",
			content:
				"После десяти лет работы в инвестициях Антон захотел чего-то более «заземленного». В 2018 году он решил открыть гастрономическую лавку и семейное кафе. Антон Носарев — о том, почему запустил бизнес внутри жилого комплекса далеко от центра Москвы и о сложностях, которые ему пришлось преодолеть.",
		},
		{
			property: "og:url",
			content: "https://quickresto.ru/secret/molodo-zeleno/",
		},
		{
			property: "og:title",
			content:
				"Секретный ингредиент кафе «Молодо-Зелено»: запуск проекта в жилом комплексе у МКАДа",
		},
		{
			property: "og:description",
			content:
				"После десяти лет работы в инвестициях Антон захотел чего-то более «заземленного». В 2018 году он решил открыть гастрономическую лавку и семейное кафе. Антон Носарев — о том, почему запустил бизнес внутри жилого комплекса далеко от центра Москвы и о сложностях, которые ему пришлось преодолеть..",
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content:
				"https://quickresto.ru/static/MediaMainBanner-bc4d1de4c584d45229b456af38be095e.png",
		},
	]

	// Проверка страницы на скролл до 50% для показа попапа о подписке
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	let isHalfScrolled = false
	const handleScroll = () => {
		const pageHeight = document.body.scrollHeight
		const pageHalfHeight = pageHeight / 2

		// Если скролл больше половины страницы то показываем попап
		if (window.scrollY >= pageHalfHeight && isHalfScrolled == false) {
			setIsModalOpen(true)

			isHalfScrolled = true
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", debounce(handleScroll, 500))

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	return (
		<DefaultLayout
			title="Секретный ингредиент кафе «Молодо-Зелено»: запуск проекта в жилом комплексе у МКАДа"
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.bg
			)}
			linkCanonical="https://quickresto.ru/secret/molodo-zeleno/"
			isFooterEnable={false}
		>
			<MainBanner data={mainBanner} className={pageStyles.pageSection} />

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[0]}
				className={pageStyles.pageSection}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[1]}
				className={pageStyles.pageSection}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[2]}
				className={pageStyles.pageSection}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[3]}
				className={pageStyles.pageSection}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[4]}
				className={pageStyles.pageSection}
			/>

			<SocialNetworkBanner
				data={socialNetworkBanner}
				className={pageStyles.pageSection}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[5]}
				className={pageStyles.pageSection}
			/>

			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[6]}
				className={pageStyles.pageSection}
				promptClassName={pageStyles.prompt__side}
			/>

			<ConsultationForm
				data={consultationForm}
				className={pageStyles.pageSection}
				request={requestForAutoOpenRestFaster}
			/>

			<FooterCompact className={styles.footer} />

			<SendPulseModal isOpen={isModalOpen} onClose={handleModalStatus} />
		</DefaultLayout>
	)
}
