import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "О проекте",
		textarea: (
			<span className={styles.letter}>
				«Молодо-зелено» — лавка с фермерскими продуктами и семейное кафе со
				вкусной и понятной кухней. Для своих гостей{" "}
				<b>мы часто проводим различные мероприятия</b>, например, у нас был
				ивент, посвящённый Гарри Поттеру. Мы одели сотрудников в костюмы,
				украсили зал и готовили тематические блюда: десерт «Тараканьи гроздья»
				для детей, сливочное пиво для взрослых. Я удивился, когда увидел, что
				взрослым такие мероприятия тоже по-настоящему интересны. Ещё пример — мы
				проводили ивент, посвящённый Шреку: предлагали в меню коктейль с глазом
				и брускетту от ослика. В общем, мы всегда стараемся добавлять в
				привычную атмосферу интересные и весёлые фишки.
			</span>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentNegativeRotate
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentOne}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentOne}
							alt="владелец кафе Антон Носарев"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>
					Взрослым тематические мероприятия
					<br className={styles.contentSmallHide} /> по-настоящему интересны
				</p>
			</div>
		),
		textarea2: (
			<span className={styles.letter}>
				Что я понял за четыре года — ресторатору важно отделять свои хотелки от
				пожеланий гостей. Он может быть уверен, что людям понравятся одни блюда,
				но на самом деле они не зайдут, и наоборот. Нужно прислушиваться к тому,
				что хотят гости: больше общаться с ними и просить оставить отзыв после
				посещения.
			</span>
		),
		prompt: (
			<div
				className={classNames(
					styles.promptTypeOne,
					styles.promptOne,
					styles.prompt__pos1
				)}
			>
				<img
					className={classNames(styles.icon, styles.icon__one)}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>Ресторатору важно отделять свои хотелки от пожеланий гостей</p>
			</div>
		),
	},
	{
		heading: "Бизнес в жилом комплексе более душевный",
		textarea: (
			<span className={styles.letter}>
				Изначально я собирался открыть только гастрономическую лавку со свежими
				фермерскими продуктами: сырами, мясом, овощами, фруктами, молочными
				продуктами.{" "}
				<strong>
					Меня всегда вдохновляли маленькие семейные магазинчики в жилых
					комплексах.
				</strong>{" "}
				В них сотрудники знают каждого посетителя по имени и помнят, какие
				товары они любят больше всего. Я хотел у себя такую же приятную
				атмосферу.
				<br />
				<br />
				В ЖК «Варшавское шоссе 141» я нашёл подходящее помещение, но оно было
				почти в два раза больше, чем нужно под лавку, — 107 м². Там были большие
				прекрасные окна и место для кухни — сюда идеально вписывалось кафе. Мне
				очень нравится вкусная, интересная еда, я люблю ходить по разным
				ресторанам и пробовать что-то новое, поэтому недолго думая решил
				арендовать помещение и открыть в нём и лавку, и кафе.
				<br />
				<br />
				Когда я выбирал комплекс, то обращал внимание на количество квартир и
				уровень жизни жильцов.{" "}
				<b>
					Мне была нужна платёжеспособная аудитория, потому что фермерские
					товары дороже аналогичных продуктов в супермаркетах.
				</b>{" "}
				В ЖК «Варшавское шоссе 141» порядка 3 500 квартир, в которых живёт 9 000
				человек. Это очень хороший собственный трафик — он спас нас в пандемию.
				Когда людям нельзя было гулять, у многих заведений были проблемы. А к
				нам в лавку и кафе жильцы без проблем спускались в тапочках, покупали
				товары, заказывали с собой еду и уходили обратно в квартиры. В пандемию
				у нас бывала даже более высокая выручка, чем до неё.
			</span>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentPositiveRotate
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentTwo}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTwo}
							alt="входная группа кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>
					3 500 квартир с 9 000 человек — хороший трафик,
					<br className={styles.contentSmallHide} />
					который <br className={styles.contentSmallShow} /> спас нас в пандемию
				</p>
			</div>
		),
		textarea2: (
			<span className={styles.letter}>
				Открыть лавку и кафе в одном помещении было не только эмоциональным
				решением, но и рациональным. Мне было важно, чтобы продукты в лавке
				всегда были свежими, вкусными и красивыми. Но овощи и фрукты не всегда
				быстро раскупают, и на них могут появиться небольшие помятости. Если бы
				не было кафе, то пришлось бы торговать такими или списывать. А так как
				кафе есть, то повара каждый день забирают на кухню фрукты и овощи,
				которые выглядят наименее привлекательно. Таким образом списания в лавке
				получаются минимальные — до 10%. Знаю, что в некоторых магазинах они
				могут доходить до 20% — это очень много.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.prompt__pos2)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>
					Списания в моей лавке
					<br />
					минимальные — до 10%
				</p>
			</div>
		),
	},
	{
		heading:
			"Название «Молодо-зелено» символизирует моё состояние, когда я открывал проект",
		textarea: (
			<span className={styles.letter}>
				Перед запуском бизнеса я готовился. Посетил несколько мастер-классов, во
				время которых побывал в разных ресторанах и увидел работу изнутри.
				Пообщался с владельцами нескольких кафе в районе, в котором жил. То есть
				к открытию своего заведения у меня были небольшие представления о сфере
				общепита. Но в целом эта отрасль была для меня новая.
			</span>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentNegativeRotate
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentThree}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentThree}
							alt="интерьер кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={styles.media__one}>
					Моё состояние при открытии как раз
					<br className={classNames(styles.contentSmallHide)} /> и объясняет
					название «Молодо-зелено»
				</p>
			</div>
		),
		textarea2: (
			<span className={styles.letter}>
				Во мне было очень много желания открыть свой бизнес, но не так много
				понимания, как это сделать правильно — то моё состояние как раз и
				объясняет название «Молодо-зелено». На него меня вдохновила компания
				Virgin Ричарда Брэнсона, название которой переводится как «девственник»,
				— Брэнсон создал много компаний в разных сферах, не имея в них большого
				опыта.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.prompt__pos3)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>
					Лавка и кафе в одном помещении — эмоциональное и рациональное решение
				</p>
			</div>
		),
	},
	{
		heading:
			"Вложил в открытие лавки и кафе 7,5 млн рублей и окупил их за 2,5 года",
		textarea: (
			<span className={styles.letter}>
				В открытие лавки в 2018 году я вложил 3 млн рублей. В открытие кафе — 4
				млн рублей на этапе строительства и ещё около 0,5 млн рублей в первые
				полгода. Суммарно вышло 7,5 млн рублей. Я думаю, что сейчас эта цифра
				была бы раза в полтора выше — около 11 млн рублей. Это связано с тем,
				что материалы и оборудование стали дороже. Эти 7,5 млн рублей получилось
				окупить за 2,5 года.{" "}
				<b>Можно было быстрее, если бы не одна моя ошибка.</b> В начале работы я
				неправильно посчитал себестоимость блюд в кафе — не учёл отходы и
				возможные списания. Поэтому себестоимость получилась очень низкая и цена
				для гостя, соответственно, тоже.
			</span>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentPositiveRotate
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentFour}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFour}
							alt="фермерская лавка в кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>
					К открытию заведения у меня были небольшие
					<br className={styles.contentSmallHide} /> представления{" "}
					<br className={styles.contentSmallShow} /> об общепите
				</p>
			</div>
		),
		textarea2: (
			<span className={styles.letter}>
				Первые полгода мы работали в убыток. Когда поняли, что так дальше
				нельзя, подняли цены, но это отпугнуло часть гостей. Спустя какое-то
				время к нам начали приходить другие гости, которых новая стоимость
				устраивала.{" "}
				<b>Через год после открытия мы начали зарабатывать прибыль.</b> Сейчас у
				нас в кафе 100–120 чеков в день. Средний чек — около 700 рублей.
				Небольшие чеки с кофе и булочкой — 200–300 рублей. Чеки с едой в районе
				1 000 рублей. В лавке мы тоже делаем около 100 продаж в день. Размер
				среднего чека зависит от сезона, но примерно от 500 до 700 рублей.
			</span>
		),
		prompt: (
			<div
				className={classNames(
					styles.promptTypeOne,
					styles.promptFour,
					styles.prompt__pos4
				)}
			>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>7,5 млн рублей получилось окупить за 2,5 года</p>
			</div>
		),
	},
	{
		heading: "Сотрудники — самая большая проблема в общепите",
		textarea: (
			<span className={styles.letter}>
				На прошлой работе у меня была руководящая должность. Но этот опыт мне
				мало помог, потому что в инвестиционной сфере одни люди и проблемы, а в
				общепите совершенно другие. И вообще с людьми оказалось гораздо сложнее,
				чем с цифрами. Мне казалось — напиши инструкцию, и человек будет по ней
				работать, а по факту так не получается. Руководитель ведь не может
				стоять над душой каждого человека и контролировать, что он делает.
				Поэтому для меня{" "}
				<b>
					главный опыт в качестве ресторатора — это умение договариваться с
					людьми
				</b>
				, находить точки контакта, не руководить, а направлять, чтобы мы
				двигались в одну сторону.
			</span>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentNegativeRotate
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={MediaContentFive}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFive}
							alt="посетители лавки и кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>С людьми гораздо сложнее, чем с цифрами</p>
			</div>
		),
		textarea2: (
			<span className={styles.letter}>
				Сотрудники — это самая большая проблема в общепите, потому что повара
				очень «летучие» люди: поработают в одном месте, устанут, пойдут в другое
				место. Сейчас мы уже на этапе отбора стараемся отсеивать таких людей и
				выбирать более стабильных, которые готовы долго трудиться на одном
				месте. Например, в текущей команде многие ребята работают больше двух
				лет. Мы ищем сотрудников на разных площадках: менеджеров — на
				Хедхантере, работников кухни — на Авито. Но лучше всего сейчас работают
				Telegram-чаты — с них приходит больше кандидатов, чем со всех остальных
				площадок.
			</span>
		),
		prompt: (
			<div
				className={classNames(
					styles.promptTypeOne,
					styles.promptFive,
					styles.prompt__pos5
				)}
			>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>Повара очень «летучие» люди</p>
			</div>
		),
	},
	{
		heading: "В Quick Resto есть всё, что мне нужно для ведения бизнеса:",
		textarea: (
			<span className={styles.letter}>
				Я как владелец занимаюсь аналитикой бизнеса — в этом мне сильно помогает
				система автоматизации Quick Resto. Изначально у нас была самая базовая
				версия, но потом мы докупили продвинутый функционал. Он позволяет
				анализировать, как часто постоянные гости делают покупки в лавке,
				строить АВС-отчёты, смотреть, какие блюда в кафе и товары в лавке лучше
				продаются, какие из них более маржинальные. По этим данным я принимаю
				решение, какие позиции убрать, а какие оставить.
			</span>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentPositiveRotate
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={MediaContentSix}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSix}
							alt="система автоматизации кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>
					Аналитика продаж, мобильное приложение, система лояльности — всё это
					есть в Quick Restо
				</p>
			</div>
		),
		textarea2: (
			<span className={styles.letter}>
				Благодаря Quick Resto у нас есть своё приложение в App Store и Google
				Play. Оно помогло во время пандемии, когда мы практически полностью
				переориентировались на доставку, — с него приходило большое количество
				заказов.{" "}
				<b>
					Через приложение мы настроили кешбэк для гостей и систему лояльности,
				</b>{" "}
				при которой наливаем в подарок каждый седьмой кофе.
			</span>
		),
	},
	{
		heading: (
			<>
				Следующее семейное кафе открою в центре Москвы, чтобы{" "}
				<br className={styles.contentSmallShow} /> можно было масштабироваться
			</>
		),
		textarea: (
			<span className={styles.letter}>
				Основной вывод, который я для себя сделал за время, которое занимаюсь
				лавкой и кафе, — если хочешь масштабировать общепит, то он должен
				находиться в центре Москвы. В идеале внутри третьего или Садового
				кольца. У нас классная локация. Очень много постоянных гостей, и это
				даёт определенную стабильность. Но расти не получается.{" "}
				<b>В такие спальные районы очень сложно приводить людей извне.</b>{" "}
				Поэтому я планирую свой следующий проект открывать ближе к центру. Хочу
				продолжать семейную тему и организовывать интересные события. Мне
				кажется, этого сейчас многим людям не хватает — это гипотеза, которую я
				собираюсь протестировать.
			</span>
		),
		prompt: (
			<div
				className={classNames(
					styles.promptTypeOne,
					styles.promptSix,
					styles.prompt__pos6
				)}
			>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>Хочешь масштабировать общепит — открывайся в центре Москвы</p>
			</div>
		),
		align: styles.columnReverse,
	},
]
