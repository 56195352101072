import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="кафе Молодо-Зелено"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="кафе Молодо-Зелено"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img
				className={styles.desktopBanner__desktop}
				src={DesktopMediaMainBanner}
				alt="кафе Молодо-Зелено"
				loading="lazy"
			/>
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Запуск кафе в жилом <br className={styles.contentSmallShow} /> комплексе
				у МКАДа. <br className={styles.contentSmallShow} />{" "}
				<span className={styles.quote}>Кейс «Молодо-зелено»</span>
			</>
		),
	},
	heading: "Открыть формат «лавка + кафе» было правильным решением",
	textarea: (
		<>
			После десяти лет работы в инвестициях Антон захотел чего-то более
			«заземленного»: больше общаться с людьми и видеть результаты своего труда
			в реальной жизни, а не на бумаге. Для этого в 2018 году он решил открыть
			гастрономическую лавку и семейное кафе. Антон Носарев — о том, почему
			запустил бизнес внутри жилого комплекса далеко от центра Москвы и о
			сложностях, которые ему пришлось преодолеть.
		</>
	),
	video: {
		src: "https://www.youtube.com/embed/k9CIngnQFt4",
		textarea: (
			<p className={styles.textarea}>
				Открывал магазин овощей <br className={styles.contentMobile} /> и
				фруктов и случайно стал <br className={styles.contentMobile} />{" "}
				ресторатором
			</p>
		),
	},
}
